import React from 'react';
import {Link} from 'gatsby';
import Home from './home';
import Links from '../components/Links';
import styles from '../components/styles.module.css';


const IndexPage = () => (
  <body>
  <Home/>
  </body>
);

export default IndexPage;
